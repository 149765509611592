import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Página no encontrada</h1>
    <p>Esta ruta no existe. Si crees que ha sido por un error, puedes contactarnos en rtt@domainmx.mx
      </p>
  </Layout>
)

export default NotFoundPage
